<template>
    <div>
        <!--        <div style="font-size: .5rem" @click="s">reload</div>-->
        <div class="btn">
            <van-icon @click="useScan" v-if="is_teacher" color="white" size="40" name="scan"/>
            <van-icon @click="show=true" v-else size="40" color="white" name="qr"/>
        </div>
        <van-tabs v-model="active">
            <van-tab title="未开始">
                <div v-for="(item,index) in unstart"
                     @click="signUp(item,index)" style="padding:.2rem .3rem;font-size: .3rem">
                    <div class="card" :style="{'background':item.color,'color':item.status!==5?'black':'white'}">
                        <h3 style="margin: .15rem">
                            {{item.name||''}}
                        </h3>
                        <div style="flex: 1">
                            {{item.date}} {{item.start_time}} {{item.end_time}}
                        </div>
                        <div style="flex: 1">
                            {{item.student_name||''}}
                        </div>
                        <div style="flex: 1">
                            <van-tag style="padding:.1rem .2rem " plain round type="success">
                                {{item.status===1?'未签到':'已签到'}}
                            </van-tag>

                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已开始">
                <div v-for="(item,index) in started"
                     @click="signUp(item,index)" style="padding:.2rem .3rem;font-size: .3rem">
                    <div class="card" :style="{'background':item.color,'color':item.status!==5?'black':'white'}">
                        <h3 style="margin: .15rem">
                            {{item.name||''}}
                        </h3>
                        <div style="flex: 1">
                            {{item.date}} {{item.start_time}} {{item.end_time}}
                        </div>
                        <div style="flex: 1">
                            {{item.student_name||''}}
                        </div>
                        <div style="flex: 1">
                            <van-tag style="padding:.1rem .2rem " plain round type="success">
                                {{item.status===1?'未签到':'已签到'}}
                            </van-tag>

                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <van-overlay :show="show">
            <div class="wrapper" @click="reset">
                <div class="block" @click.stop>
                    <vue-qr :text="text" :size="300"></vue-qr>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import vueQr from 'vue-qr'

    import wx from 'weixin-js-sdk'
    import config from "../config";
    import {Dialog, Toast} from 'vant';


    export default {
        name: "enterClass",
        data() {
            return {
                active: 0,

                unstart: [],
                started: [],

                today: '',
                user_id: '',
                student_id: '',

                text: '',
                show: false,

                type: ['active', 'finished'],
                is_teacher: true,
            }
        },
        components: {
            vueQr
        },
        created() {
            this.is_teacher = Number(localStorage.getItem('is_teacher'));
            this.user_id = localStorage.getItem('user_id');
            //学生信息
            let date = new Date();
            let _year = date.getFullYear(),
                _month = date.getMonth() + 1,
                _day = date.getDate();
            _month = _month < 10 ? '0' + _month : _month;// 获取当前月份的日期，不足10补0
            _day = _day < 10 ? '0' + _day : _day;// 获取当前几号，不足10补0
            this.today = _year + '-' + _month + '-' + _day;
            if (this.is_teacher) {
                //老师扫码
                this.initTeacherScan();
            } else {
                this.initStudentInfo()
            }
        },
        methods: {
            initTeacherScan() {
                let _this = this;

                let url = window.location.href;
                this.config({url, jsApiList: ['scanQRCode']})
            },
            // s(){
            //     localStorage.clear()
            // },
            //获取学生信息
            initStudentInfo() {
                this.type.forEach((item) => {
                    this.$axios.get('miniprogramteacher/specialAllClass?type=' + item + '&date=' + this.today + '&wechat_user_id=' + this.user_id).then((res) => {
                        if (res.data.code === '0000') {
                            item === 'active' ? (this.unstart = res.data.content.classes || []) : (this.started = res.data.content.classes || []);
                            if (this.student_id === '' && res.data.content.classes.length !== 0) {
                                this.student_id = res.data.content.classes[0].student_id;
                                this.text = this.student_id + '/' + this.today+'/'+new Date();
                            }
                        }
                    })
                })
            },
            //老师帮忙签到
            signUp(item, index) {
                if (!item || !this.is_teacher) {
                    return
                }
                let status = item.status === 1 ? 8 : 1;
                if (item.type === 1) {
                    this.$axios.post('miniprogramteacher/signSingle', {
                        id: item.id,
                        status,
                        wechat_user_id: this.user_id
                    }).then((res) => {
                        if (res.data.code === '0000') {
                            Toast(item.status === 1 ? '签到成功' : '取消签到');

                            this.unstart[index].status = status;
                            item.color = res.data.content.color;
                        }
                    })
                } else {
                    this.$axios.post('miniprogramteacher/signSingleCart', {
                        id: item.id,
                        status,
                        wechat_user_id: this.user_id
                    }).then((res) => {
                        if (res.data.code === '0000') {
                            Toast(item.type === 1 ? '取消签到' : '签到成功');
                            this.unstart[index].status = status;
                            item.color = res.data.content.color;
                        }
                    })
                }

            },

            //调取摄像头扫码
            useScan() {
                let _this = this;
                console.log('saoma')
                wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        let result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        _this.getScan(result);
                    },
                    fail(e) {
                        console.log(JSON.stringify(e))
                        console.log(e)
                    }
                });
            },
            getScan(result) {
                let re_arr = result.split('/');
                let student_id = re_arr[0];
                this.getStudentInfo(student_id)
            },
            getStudentInfo(student_id) {
                this.type.forEach((item) => {
                    this.$axios.get('miniprogramteacher/specialStudentClass?type=' + item +
                        '&date=' + this.today +
                        '&wechat_user_id=' + this.user_id +
                        '&student_id=' + student_id.trim()).then((res) => {
                        if (res.data.code === '0000') {
                            item === 'active' ? (this.unstart = res.data.content.classes || []) : (this.started = res.data.content.classes || []);
                        }
                    })
                })
            },
            reset(){
                this.show = false;
                if(!this.is_teacher&&this.user_id!=='')
                {
                    this.initStudentInfo()
                }

            },
            barCode(data){
                JsBarcode('#barcode', data, {
                    format: 'CODE39',
                    lineColor: '#000',
                    background: '#EBEEF5',
                    width: 2,
                    height: 60,
                    displayValue: false
                })
            },
            config({url, jsApiList}, callback) {
                axios.post('wechatJsConfig', {
                    "apis": jsApiList,
                    url
                }, {
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                }).then((res) => {
                    if (res.data.code === '0000') {
                        let configs = JSON.parse(res.data.content.config);
                        wx.config({
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: configs.appId, // 必填，公众号的唯一标识
                            timestamp: configs.timestamp, // 必填，生成签名的时间戳
                            nonceStr: configs.nonceStr, // 必填，生成签名的随机串
                            signature: configs.signature,// 必填，签名
                            jsApiList // 必填，需要使用的JS接口列表
                        });
                        //通过ready接口处理成功验证
                        wx.ready(function () {
                            callback ? callback() : '';
                        })
                        wx.error(function (err) {
                            throw new Error(err)
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .btn {
        padding: .1rem;
        text-align: right;
        position: fixed;
        bottom: 1rem;
        z-index: 999;
        right: .2rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid lightgray;
        background: red;
    }

    .card {
        padding-bottom: .1rem;
        height: 2.5rem;
        border-radius: .1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        background-color: #fff;
    }
</style>
